export default {
  list: '/course/manage/exam/list',
  createdtest: '/course/manage/exam/create',
  randomQuestionsSave: '/course/manage/exam/question/setting/save',
  randomTestSave: '/course/manage/exam/answer/setting/save',
  releaseTestPaper: '/course/manage/exam/publish',
  stopTestPaper: '/course/manage/exam/stop',
  classSelectList: '/course/manage/exam/search/classItem',
  shareLink: '/course/manage/exam/share/link',
  testPaperList: '/course/manage/exam/class/list',
  distributionTest: '/course/manage/exam/class/bind',
  cancelDistributionTest: '/course/manage/exam/class/unBind',
  testDetail: '/course/manage/exam/info',
  testDel: '/course/manage/exam/remove',
  testResult: '/course/manage/exam/score/list',
  testResultClass: '/course/manage/exam/detail/class/list',
  testResultDetail: '/course/manage/exam/user/record/detail',
  testExport: '/course/manage/exam/export',
  addCustom: '/course/manage/exam/item/create',
  questionList: '/course/manage/question/store/page',
  questionImport: '/course/manage/question/store/import/excel',
  questionDownload: '/course/manage/question/store/download/template',
  questionDetail: '/course/manage/question/store/detail/page',
  createdQuestionBank: '/course/manage/question/store/create',
  questionDel: '/course/manage/question/store/delete/question',
  evaluationList: '/eval/identify/statistics/findStatistics',
  evaluationExport: '/eval/identify/statistics/statisticsExport',
  personalDetailList: '/eval/identify/result/findList',
  personalDetailExport: '/eval/identify/result/export',

  create: '/course/manage/survey/create',
  download: '/course/manage/survey/download/template',
  addExcel: '/course/manage/survey/import/excel',
  makeRules: '/course/manage/survey/make/rules',
  QuesList: '/course/manage/survey/query/list',
  QuesDelete: '/course/manage/survey/delete',
  preview: '/course/manage/survey/preview',
  status: '/course/manage/survey/change/survey/status',
  quesLink: '/course/manage/survey/share',
  recordList: '/course/manage/survey/query/submitted/answer/records/list',
  surveyExport: '/course/manage/survey/answer/details/excel/export',
}
