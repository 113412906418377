import config from './config/examination'
import axios from '@/utils/request'
export const getExaminationList = params => axios.post(config.list, params) // 查询考试列表
export const getCreatedtest = params => axios.post(config.createdtest, params) // 创建考试
export const randomQuestionsSave = params => axios.post(config.randomQuestionsSave, params) // 随机试题设置保存
export const randomTestSave = params => axios.post(config.randomTestSave, params) // 随机考试设置保存
export const releaseTestPaper = params => axios.post(config.releaseTestPaper, params) // 发布考试
export const stopTestPaper = params => axios.post(config.stopTestPaper, params) // 停止考试
export const classSelectList = params => axios.post(config.classSelectList, params) // 班级下拉列表
export const shareLink = params => axios.post(config.shareLink, params) // 分享链接
export const testPaperList = params => axios.post(config.testPaperList, params) // 分配考试班级列表
export const distributionTest = params => axios.post(config.distributionTest, params) // 分配考试
export const cancelDistributionTest = params => axios.post(config.cancelDistributionTest, params) // 取消分配考试
export const testDetail = params => axios.post(config.testDetail, params) //考试详情
export const addCustom = params => axios.post(config.addCustom, params) //添加自定义选项
export const testDel = params => axios.post(config.testDel, params) //考试删除
export const testResult = params => axios.post(config.testResult, params) //考试成绩列表
export const testResultClass = params => axios.post(config.testResultClass, params) //考试成绩列表班级下拉列表
export const testExport = params => axios.post(config.testExport, params, { responseType: 'blob' }) //考试成绩导出
export const testResultDetail = params => axios.post(config.testResultDetail, params) //考试成绩详情
export const questionList = params => axios.post(config.questionList, params) // 题库列表
export const questionImport = params => axios.post(config.questionImport, params) // 题库导入
export const questionDownload = params =>
  axios.post(config.questionDownload, params, { responseType: 'blob' }) // 题库下载
export const questionDetail = params => axios.post(config.questionDetail, params) // 题库详情
export const createdQuestionBank = params => axios.post(config.createdQuestionBank, params) // 创建题库
export const questionDel = params => axios.post(config.questionDel, params) // 删除题库题目

export const createQs = params => axios.post(config.create, params) // 创建问卷
export const download = params => axios.post(config.download, params, { responseType: 'blob' }) // 下载模板
export const addExcel = params => axios.post(config.addExcel, params) // 模板导入
export const makeRules = params => axios.post(config.makeRules, params) // 规则制定
export const QuesList = params => axios.post(config.QuesList, params) //查询问卷列表
export const QuesDelete = params => axios.post(config.QuesDelete, params) //删除问卷
export const preview = params => axios.post(config.preview, params) //删除问卷
export const status = params => axios.post(config.status, params) //切换状态
export const quesLink = params => axios.post(config.quesLink, params) //分享
export const recordList = params => axios.post(config.recordList, params) //下载用户列表
export const surveyExport = params =>
  axios.post(config.surveyExport, params, { responseType: 'blob' }) //下载接口

export const evaluationList = params => axios.post(config.evaluationList, params) //测评列表
export const evaluationExport = params =>
  axios.post(config.evaluationExport, params, { responseType: 'blob' }) //测评导出
export const personalDetailList = params => axios.post(config.personalDetailList, params) //个人详情测评列表
export const personalDetailExport = params =>
  axios.post(config.personalDetailExport, params, { responseType: 'blob' }) //个人详情测评导出
