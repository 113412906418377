<template>
  <div class="downLoadList">
    <table-list
      title="问卷信息列表"
      :loading="loading"
      :data="QuestionRecordList"
      :columns="columns(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '导出',
    method: _this.handleExport,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'publishedTime',
    label: '提交时间',
  },
  {
    prop: 'phone',
    label: '手机号',
  },
  {
    prop: 'userName',
    label: '用户昵称',
  },
  {
    prop: 'address',
    label: '来自IP',
  },
]
import TableList from '@/components/TableList'
import { recordList, surveyExport } from '@/api/examination'
import to from 'await-to'
export default {
  name: 'QuestionRecordList',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      columns,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      QuestionRecordList: [],
      SelectRecordList: [],
      surveyId: '',
    }
  },
  mounted() {
    this.surveyId = this.$route.query.surveyId
    this.getListdata()
  },
  methods: {
    async getListdata() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(recordList({ current, size, surveyId: this.surveyId }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.QuestionRecordList = res.data.list
    },
    async handleExport() {
      if (!this.SelectRecordList.length) return this.$message.warning('请先选择用户')
      let userIdList = []
      this.SelectRecordList.map(item => userIdList.push(item.userId))
      const [res, err] = await to(
        surveyExport({ responseType: 'blob', surveyId: this.surveyId, userIdList: userIdList }),
      )
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '问卷.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    //选中的列表
    handleSelectionChange(val) {
      this.SelectRecordList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
    },
    handleCurrentChange(current) {
      this.pager.current = current
    },
  },
}
</script>
